import React, { useEffect, useState } from "react";

const ThemeStyles = (theme) => {
  const [windowwidth, setWindowwidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowwidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSystemDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const SelectedTheme =
    theme === "dark" || (theme === "system" && isSystemDark);
  // const SelectedTheme = theme === 'dark' || (theme === 'system');
  const placeholderClass = SelectedTheme
    ? "placeholder-dark"
    : "placeholder-light";
  const widthBorder =
    windowwidth > 768 && windowwidth < 1024
      ? {
          borderColor: SelectedTheme ? "#1E3354" : "#1E3354",
          borderWidth: "2px",
          borderStyle: "solid",
        }
      : {};

  return {
    component: {
      backgroundColor: SelectedTheme ? "#242222" : "white",
      color: SelectedTheme ? "white" : "#787878",
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 5px;",
    },
    // boxShadow: { boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 5px;" },
    bg: {
      backgroundColor: SelectedTheme ? "#5E6267" : "white",
      color: SelectedTheme ? "white" : "#363942",
    },
    bgColor: {
      backgroundColor: SelectedTheme ? "#5E6267" : "#E7ECEF",
      color: SelectedTheme ? "white" : "#787878",
    },
    bgTitle: {
      backgroundColor: SelectedTheme ? "#1E3354" : "#1E3354",
      color: SelectedTheme ? "white" : "white",
    },
    input: {
      backgroundColor: SelectedTheme ? "#2C2B2B" : "#E7ECEF",
      color: SelectedTheme ? "white" : "#787878",
    },
    toggle: {
      backgroundColor: SelectedTheme ? "#2C2B2B" : "white",
      color: SelectedTheme ? "white" : "#787878",
    },

    border: {
      borderColor: SelectedTheme ? "#1552b2" : "#1552b2",
      borderWidth: "2px",
      borderStyle: "solid",
    },
    darkblue: {
      color: SelectedTheme ? "white" : "#363942",
    },
    bluetext: {
      color: SelectedTheme ? "white" : "#1552B2",
    },
    blacktext: {
      color: SelectedTheme ? "white" : "black",
    },
    Svgcolor: {
      fill: SelectedTheme ? "white" : "white",
      stroke: SelectedTheme ? "white" : "white",
    },
    SvgBlue: {
      fill: SelectedTheme ? "#1552b2" : "#1552b2",
      stroke: SelectedTheme ? "#1552b2" : "#1552b2",
    },
    SvgStroke: {
      stroke: SelectedTheme ? "white" : "#1552b2",
    },
    sidebarborder: {
      borderColor: SelectedTheme ? "white" : "rgba(120, 120, 120)",
    },
    fgcolor: {
      fgColor: SelectedTheme ? "black" : "white",
    },
    bgcolor: {
      bgColor: SelectedTheme ? "white" : "black",
    },
    placeholderClass,
    widthBorder,
  }; 
};

export default ThemeStyles;
