import React from 'react';

const CircularProgress = ({ progress }) => {
    if (progress === 0) {
        return (
            <div className="text-center">
                <span className="text-4xl">0%</span>
            </div>
        );
    }

    const radius = 38;
    const stroke = 5;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <svg
            height={radius * 2}
            width={radius * 2}
            className="circular-progress">
            <circle
                // stroke="#e6e6e6"
                fill="transparent"
                strokeWidth={stroke}
                strokeLinecap="round"
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            <circle
                stroke="#4caf50"
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={circumference + ' ' + circumference}
                strokeDashoffset={strokeDashoffset}
                strokeLinecap="round"
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                className="progress"
                style={{
                    transition: 'stroke-dashoffset 0.35s',
                    transform: 'rotate(-90deg)',
                    transformOrigin: '50% 50%'
                }}
            />
            <text
                x="50%"
                y="50%"
                dy=".3em"
                textAnchor="middle"
                fontSize="12px"
                fill="#000"
            >
                {`${progress}%`}
            </text>
        </svg>
    );
}

export default CircularProgress;
