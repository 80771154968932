import React from "react";
import {useNavigate} from 'react-router-dom';
import routes from "../routes";

function HeaderAllPage({ currentPath }) {

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const getBasePath = (path) => {
    const segments = path.split('/');
    if (segments.length > 2) {
      segments.pop();
      return segments.join('/');
    }
    return path;
  };

  const basePath = getBasePath(currentPath);
  const currentRoute = routes.find((route) => route.path === currentPath);

  const routeName = currentRoute ? currentRoute.name : "";

  

  return (
    <>
      {/* mobile */}
      <header className="lg:hidden relative  top-0  flex w-full h-[70px] bg-[#1552B2] ">
        <div className=" flex w-full items-center justify-between px-4">
          <button  onClick={handleGoBack} className="flex items-center justify-center w-[20px] h-[20px] rounded-xl  text-white" >
          <span class="material-symbols-outlined !text-[16px] ml-1 !font-bold">
            arrow_back_ios
          </span>
          </button>
          <div className=" text-white">
            {routeName}
          </div>
          <div></div>
        </div>
      </header>
    </>
  );
}

export default HeaderAllPage;
