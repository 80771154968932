import React, { useEffect, useState } from "react";
import CardOne from "../../components/CardOne";
import CardFour from "../../components/CardFour";
import CardThree from "../../components/CardThree";
import CardTwo from "../../components/CardTwo";
import SmartAttendance from "../../components/SmartAttendance";
import MeetingCalendar from "../../components/MeetingCalendar";
import RecentTask from "../../components/RecentTask";
import { Link } from "react-router-dom";
import HeaderHome from "../../components/Headerhome";
import QuickCard from "../../components/QuickCard";
import QuickAll from "../../components/QuickAll";
import CeoCard from "../../components/CeoCard";
import CeoInbox from "../../components/CeoInbox";
import CeoOverallTask from "../../components/CeoOverallTask";
import CeoOverallproject from "../../components/CeoOverallProject";
import { jwtDecode } from "jwt-decode";
import { useTheme } from "../../components/Theme/Themecontext";
import MeetingCard from "../../AtomicComponent/MeetingCard";
import { useTranslation } from "react-i18next";
import CeoDashboardCircle from "../../components/CeoDashboardCircle";
import VoiceBot from "../../components/VoiceBot";
import { X } from "lucide-react";
import Lottie from "lottie-react";
import announcementAnimation from "../../images/FYCrXI7K14.json";
import CircularProgress from "../../components/CircularProgress";

// import Videocall from "../../components/Webrtc";

function Dashboard() {
  const { t, i18n } = useTranslation();

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const token = localStorage.getItem("token");
  const userInfo = jwtDecode(token);
  // console.log("dasboard", userInfo)

  const { styles, theme, isSystemLight } = useTheme();
  const { component, SvgStroke, blacktext, bluetext } = styles;
  const [reloadCounter, setReloadCounter] = useState(0);
  const [Announcement, setAnnouncement] = useState([]);
  const [taskInfo, setTaskInfo] = useState([]);
  const [announcePopup, setAnnouncePopup] = useState(false);
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);
  const [project, setProject] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  const RELOAD_INTERVAL = 500;

  const [meetingInfo, setMeetingInfo] = useState([]);

  const fetchUserclkInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/home/todayMeeting`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();

        setMeetingInfo(data.result);
      } else {
        throw new Error("Failed to fetch booking details");
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  const TimeFormat = (dateString) => {
    const today = new Date(dateString);
    const Hours = String(today.getHours()).padStart(2, "0");
    const Mins = String(today.getMinutes()).padStart(2, "0");
    return Hours + ":" + Mins;
  };

  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = hours + ":" + minutes + " " + ampm;
    return formattedTime;
  };

  const onClose = () => {
    setAnnouncePopup(false);
  };

  useEffect(() => {
    fetchUserclkInfo();
    const intervalId = setInterval(() => {
      setReloadCounter((prevCounter) => prevCounter + 1);
    }, RELOAD_INTERVAL);

    return () => clearInterval(intervalId);
  }, [isRefresh]);

  setTimeout(() => {
    setIsRefresh(false);
  }, 1000);

  const handleRefresh = () => {
    setIsRefresh(true);
  };

  const fetchUserTask = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/home/todayTask`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTaskInfo(data?.result);
        console.log(data?.result);
      } else {
        throw new Error("Failed to fetch booking details");
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  const GetAnouncement = () => {
    try {
      const hasPopupBeenShown = localStorage.getItem("announcementPopupShown");

      if (!hasPopupBeenShown) {
        fetch(`${baseUrl}/announcement/get`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch announcement");
            }
            return response.json();
          })
          .then((data) => {
            if (data.isSuccess) {
              const today = new Date();
              const formattedToday =
                today.getDate().toString().padStart(2, "0") +
                "-" +
                (today.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                today.getFullYear();

              const todayAnnouncements = data.result.filter((announcement) => {
                return announcement.publishDate === formattedToday;
              });

              if (todayAnnouncements.length > 0) {
                setAnnouncement(todayAnnouncements);
                setAnnouncePopup(true);
                localStorage.setItem("announcementPopupShown", "true");
              }
            }
          })
          .catch((err) => {
            console.error("Error fetching announcement:", err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextAnnouncement = () => {
    setCurrentAnnouncementIndex((prev) =>
      prev === Announcement.length - 1 ? 0 : prev + 1
    );
  };

  const previousAnnouncement = () => {
    setCurrentAnnouncementIndex((prev) =>
      prev === 0 ? Announcement.length - 1 : prev - 1
    );
  };

  const GetProject = () => {
    try {
      fetch(`${baseUrl}/home/managerProjects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch announcement");
          }
          return response.json();
        })
        .then((data) => {
          if (data.isSuccess) {
            setProject(data.result);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAnouncement();
    fetchUserTask();
    GetProject();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("announcementPopupShown");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div className=" bg-[#F2F3F5]">
        <div
          className={` ${
            i18n.language === "ar"
              ? "justify-center lg:justify-end"
              : "justify-center lg:justify-start"
          }`}
        >
          <CardOne reloadCounter={reloadCounter} />
        </div>
        <div className="lg:hidden mt-5">
          <QuickCard />
        </div>

        <div className="w-full">
          <VoiceBot />
        </div>
        {announcePopup && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
              className="fixed  inset-0 bg-black bg-opacity-50"
              onClick={onClose}
            />

            <div className="bg-white h-[60vh] rounded-lg shadow-xl p-6 max-w-md w-full mx-4 relative z-10">
              <button
                onClick={onClose}
                className="absolute text-[12px] lg:text-xl top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
              >
                <X size={20} />
              </button>

              <div className="lg:space-y-4 space-y-2">
                <div className="flex items-center justify-between">
                  <h2 className=" text-[14px] lg:text-xl font-semibold text-gray-900">
                    Announcement
                  </h2>
                </div>

                <div className="w-full h-[100px] lg:h-48 relative">
                  <Lottie
                    animationData={announcementAnimation}
                    loop={true}
                    autoplay={true}
                    className="w-full h-full object-contain"
                  />

                  {Announcement.length > 1 && (
                    <>
                      <button
                        onClick={previousAnnouncement}
                        className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-1 rounded-full shadow-md"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={nextAnnouncement}
                        className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-1 rounded-full shadow-md"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                </div>

                {Announcement.length > 1 && (
                  <div className="flex justify-center gap-2   py-2">
                    {Announcement.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setCurrentAnnouncementIndex(index)}
                        className={`w-2 h-2 rounded-full transition-colors ${
                          index === currentAnnouncementIndex
                            ? "bg-blue-600"
                            : "bg-gray-300"
                        }`}
                      />
                    ))}
                  </div>
                )}

                <h1 className="text-[14px] lg:text-lg text-gray-900">
                  {Announcement[currentAnnouncementIndex]?.title}
                </h1>
                <p className="text-[14px] lg:text-lg text-gray-600">
                  {Announcement[currentAnnouncementIndex]?.shortDesc}
                </p>

                <div className="flex justify-end  ">
                  <Link
                    to={`/home/announcement/announcementdetail?id=${Announcement[currentAnnouncementIndex]?.announcementId}`}
                  >
                    <button className="relative overflow-hidden flex items-center justify-center gap-2 px-2 py-1 lg:px-5 lg:py-2 font-bold text-white bg-blue-600 border-3 border-white  rounded-full shadow-md outline-none transition-transform duration-300 ease-in-out hover:scale-105 hover:border-white/90">
                      Know More
                      <svg
                        className="w-6 h-6 transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <div className="absolute top-0 left-0 w-[100px] h-full bg-gradient-to-r from-transparent via-white/80 to-transparent opacity-60 -translate-x-[100px] group-hover:animate-shine" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <-------------------------------------New meeting design----------------------------------------------> */}

        <div
          // style={component}
          className="p-3 w-full bg-white flex-cols items-center justify-center rounded-lg"
        >
          <div className="flex items-start justify-between">
            <div>
              <div className="flex  items-center gap-2 ">
                <h1 className="text-[14px] lg:text-[16px] font-semibold" style={blacktext}>Today Meeting</h1>
                <h1 className="w-[20px] bg-[#EBE9FE] text-[#1552b2] text-[12px] rounded-[4px] h-[20px] flex  items-center justify-center">
                  {meetingInfo.length}
                </h1>
              </div>
              <p className="text-[12px] lg:text-[14px]" style={bluetext}>Your schedule for the day</p>
            </div>
            <Link to="/home/meeting">
              <p style={bluetext} className=" text-[12px] lg:text-[14px] font-[600] cursor-pointer ">
                {t("seemore")}
              </p>
            </Link>
          </div>
          <div className="">
            {meetingInfo.length > 0 ? (
              <div
                className={`flex gap-4 xl:gap-10 p-3 scroll-container ${
                  i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
                }`}
              >
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {meetingInfo.map((meet) => (
                    <Link
                      key={meet.id}
                      to={`/home/meeting/details?meetingId=${meet?.meetingId}`}
                    >
                      <div className="border bg-[#F9FAFB] rounded-xl p-2 ">
                        <div className="flex-cols items-center ">
                          <div className="flex items-center justify-between text-[14px] mb-3">
                            <h1 style={blacktext} className=" font-medium">
                              {meet?.title}
                            </h1>
                            <div className="flex items-center gap-1">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.00016 0.333252C3.32683 0.333252 0.333496 3.32659 0.333496 6.99992C0.333496 10.6733 3.32683 13.6666 7.00016 13.6666C10.6735 13.6666 13.6668 10.6733 13.6668 6.99992C13.6668 3.32659 10.6735 0.333252 7.00016 0.333252ZM9.90016 9.37992C9.80683 9.53992 9.64016 9.62659 9.46683 9.62659C9.38016 9.62659 9.2935 9.60659 9.2135 9.55325L7.14683 8.31992C6.6335 8.01325 6.2535 7.33992 6.2535 6.74659V4.01325C6.2535 3.73992 6.48016 3.51325 6.7535 3.51325C7.02683 3.51325 7.2535 3.73992 7.2535 4.01325V6.74659C7.2535 6.98659 7.4535 7.33992 7.66016 7.45992L9.72683 8.69325C9.96683 8.83325 10.0468 9.13992 9.90016 9.37992Z"
                                  fill="#D0D5DD"
                                />
                              </svg>
                              {formatTime(meet?.from)}-{formatTime(meet?.to)}
                            </div>
                          </div>
                          <div className="flex items-center w-full justify-between text-[14px]">
                            <div className="flex">
                              {meet.attendees
                                .slice(0, 3)
                                .map((image, index) => (
                                  <img
                                    key={index}
                                    className=" w-[25px] h-[25px] rounded-full"
                                    src={image?.userImage}
                                    alt=""
                                  />
                                ))}
                              {meet.attendees.length > 3 && (
                                <p className="flex items-center justify-center text-white bg-[#1552b2] w-[25px] h-[25px] rounded-full ">
                                  +{meet.attendees.length - 3}
                                </p>
                              )}
                            </div>

                            <div className="px-2 py-1 flex justify-center items-center  border rounded-[25px] p-1 bg-[#1522b2]">
                              <a
                                href={meet.link}
                                className="text-[10px] flex flex-1 justify-center gap-1 mx-1 text-white"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              >
                                Join Meet
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <p
                style={blacktext}
                className="text-[14px] lg:text-[16px] text-center text-gray-500"
              >
                {t("noactionreq")}
              </p>
            )}
          </div>
        </div>

        {/* <-------------------------------------New task design----------------------------------------------> */}
        <div
          // style={component}
          className="p-3 w-full bg-white flex-cols items-center justify-center mt-5 rounded-lg"
        >
          <div className="flex items-start justify-between">
            <div>
              <div className="flex  items-center gap-2 ">
                <h1 className="text-[14px] lg:text-[16px] font-semibold" style={blacktext}>Today Tasks</h1>
                <h1 className="w-[20px] bg-[#EBE9FE] text-[#1552b2] text-[12px] rounded-[4px] h-[20px] flex  items-center justify-center">
                  {taskInfo.length}
                </h1>
              </div>
              <p className="text-[12px] lg:text-[14px]" style={bluetext}>The tasks assigned to you for today</p>
            </div>
            <Link to="/home/task">
              <p style={bluetext} className=" text-[12px] lg:text-[14px] font-[600] cursor-pointer ">
                {t("seemore")}
              </p>
            </Link>
          </div>
          <div className="">
            {taskInfo.length > 0 ? (
              <div
                className={`flex gap-4 xl:gap-10 p-3 scroll-container ${
                  i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
                }`}
              >
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {taskInfo.map((task) => (
                    <Link
                      key={task.meetingId}
                      to={`/home/task/taskdetail?taskId=${task?.taskId}`}
                    >
                      <div className="border bg-[#F9FAFB] rounded-xl p-2 ">
                        <div className="flex-cols items-center ">
                          <div className="flex items-center justify-between text-[14px] mb-3">
                            <h1 style={blacktext} className=" font-medium">
                              {task?.title}
                            </h1>
                            <div className="flex items-center gap-1">
                              Due date
                            </div>
                          </div>
                          <div>
                            <div className="flex justify-between  mt-2">
                              <div className="flex">
                                <div className="self-end flex lg:gap-1 gap-3 xl:gap-3">
                                  <div>
                                    <div
                                      className={`w-auto h-[20px] px-2 py-2 flex items-center justify-center rounded-[20px] ${
                                        task.status === "Pending"
                                          ? "bg-[#EA001B]"
                                          : task.status === "In Progress"
                                          ? "bg-[#D6F4E3] text-[#1F7D66] fill-[#1F7D66]"
                                          : task.status === "Completed"
                                          ? "bg-green-500"
                                          : ""
                                      }`}
                                    >
                                      <svg
                                        width="10"
                                        height="11"
                                        viewBox="0 0 10 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4.99967 1.3335C2.70384 1.3335 0.833008 3.20433 0.833008 5.50016C0.833008 7.796 2.70384 9.66683 4.99967 9.66683C7.29551 9.66683 9.16634 7.796 9.16634 5.50016C9.16634 3.20433 7.29551 1.3335 4.99967 1.3335ZM6.81217 6.98766C6.75384 7.08766 6.64967 7.14183 6.54134 7.14183C6.48717 7.14183 6.43301 7.12933 6.38301 7.096L5.09134 6.32516C4.77051 6.1335 4.53301 5.71266 4.53301 5.34183V3.6335C4.53301 3.46266 4.67467 3.321 4.84551 3.321C5.01634 3.321 5.15801 3.46266 5.15801 3.6335V5.34183C5.15801 5.49183 5.28301 5.71266 5.41217 5.78766L6.70384 6.5585C6.85384 6.646 6.90384 6.83766 6.81217 6.98766Z"
                                          fill="currentColor"
                                        />
                                      </svg>

                                      <p className=" text-[8px] md:text-[10px]">
                                        {task.status === "Pending"
                                          ? "Initiated"
                                          : task.status === "In Progress"
                                          ? "In Progress"
                                          : task.status === "Completed"
                                          ? "Completed"
                                          : ""}
                                      </p>
                                    </div>
                                  </div>

                                  <div>
                                    <div
                                      className={`w-[50px] lg:w-[40px] xl:w-[50px] h-[20px] flex items-center justify-center rounded-[20px] ${
                                        task.priority === 2 ||
                                        task.priority === "2"
                                          ? "bg-[#F95555]"
                                          : task.priority === 1 ||
                                            task.priority === "1"
                                          ? "bg-[#F83600]"
                                          : "bg-[#1552B2]"
                                      }`}
                                    >
                                      <p className="text-[#F8F6FF] text-[10px]">
                                        {task.priority === 2 ||
                                        task.priority === "2"
                                          ? "High"
                                          : task.priority === 1 ||
                                            task.priority === "1"
                                          ? "Medium"
                                          : "Low"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p>{formatTime(task.to)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <p
                style={blacktext}
                className="text-[14px] text-center text-gray-500"
              >
                {t("noactionreq")}
              </p>
            )}
          </div>
        </div>

        {/* <-------------------------------------New project design----------------------------------------------> */}
        <div
          // style={component}
          className="p-3 w-full bg-white flex-cols items-center justify-center mt-5 rounded-lg"
        >
          <div className="flex items-start justify-between">
            <div>
              <div className="flex  items-center gap-2 ">
                <h1 className="text-[14px] lg:text-[16px] font-semibold" style={blacktext}>Recent Projects</h1>
                <h1 className="w-[20px] bg-[#EBE9FE] text-[#1552b2] text-[12px] rounded-[4px] h-[20px] flex  items-center justify-center">
                  {project.length}
                </h1>
              </div>
              <p className="text-[12px] lg:text-[14px]" style={bluetext}>The Projects assigned to you.</p>
            </div>
            <Link to="/home/task">
              <p style={bluetext} className="text-[12px] lg:text-[14px] font-[600] cursor-pointer ">
                {t("seemore")}
              </p>
            </Link>
          </div>
          <div className="">
            {project.length > 0 ? (
              <div
                className={`flex gap-4 xl:gap-10 xl:p-3 scroll-container ${
                  i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
                }`}
              >
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {project.map((task) => (
                    <Link
                      key={task.taskId}
                      to={`/home/projectdashboard/allproject/viewproject?id=${task?.projectId}`}
                    >
                      <div className="border bg-[#F9FAFB] rounded-xl p-2 ">
                        <div className="flex-cols items-center ">
                          <div className="flex justify-between text-[14px] mb-3">
                            <div className="flex-cols w-[65%] items-center">
                              <h1 style={blacktext} className=" font-medium">
                                {task?.projectName}
                              </h1>
                              {task.techStack.map((tech) => (
                                <p className="text-[12px]">{tech}</p>
                              ))}
                              <div>
                                <div className="flex justify-between items-start mt-2">
                                  <div className="flex">
                                    <div className="self-end flex lg:gap-1 gap-5 xl:gap-3">
                                      <div className="flex-cols">
                                        <h1 className="mb-3 text-[12px] md:text-[14px]">Team Members</h1>
                                        <div className="flex">
                                          {task.teamMember.map((member) => (
                                            <img
                                              key={member.userId}
                                              className="w-[15px] h-[15px] md:w-[30px] md:h-[30px] rounded-full"
                                              src={member?.userImage}
                                              alt=""
                                            />
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex-cols">
                                    <h1 className="mb-3 text-[12px] md:text-[14px]">Due Date</h1>
                                    <p className="text-[12px] md:text-[14px]">{formatTime(task.projectEndDate)}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex justify-end w-[35%] place-items-start gap-1">
                              <CircularProgress
                                progress={task.projectProgress}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <p
                style={blacktext}
                className="text-[14px] text-center text-gray-500"
              >
                {t("noactionreq")}
              </p>
            )}
          </div>
        </div>
        {/* <Videocall/> */}
      </div>
    </>
  );
}

export default Dashboard;

// {userInfo.role === 1 || userInfo.role === 0 ? (
//   <div className="mt-4 ">
//     <div
//       className={`my-3 flex justify-between ${
//         i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
//       }`}
//     >
//       <p style={bluetext} className="text-[14px] font-[600]">
//         {t("projects")}
//       </p>
//       <Link to="/home/overallinprogresstask">
//         <p style={bluetext} className="text-[14px] font-[600]">
//           {t("seemore")}
//         </p>
//       </Link>
//     </div>
//     <CeoOverallTask reloadCounter={reloadCounter} />
//   </div>
// ) : null}

{
  /* <div className="mt-4 ">
          <div className="my-3 hidden md:flex justify-between">
            <p className="text-[16px] font-[600]">Inbox</p>
            <button onClick={handleRefresh}>
              <span className="material-symbols-outlined">refresh</span>
            </button>
          </div>
          <CeoInbox isRefresh={isRefresh} />
        </div> */
}

{
  /* {userInfo.role === 1 || userInfo.role === 0 ? (
          <div className="mt-4 ">
            <div
              className={`my-3 flex justify-between ${
                i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              <p style={bluetext} className="text-[14px] font-[600]">
                {t("projects")}
              </p>
              <Link to="/home/overallinprogresstask">
                <p style={bluetext} className="text-[14px] font-[600]">
                  {t("seemore")}
                </p>
              </Link>
            </div>
            <CeoOverallTask reloadCounter={reloadCounter} />
          </div>
        ) : null} */
}

{
  /* <div className="mt-4 ">
          <SmartAttendance reloadCounter={reloadCounter} />
        </div> */
}

{
  /* {userInfo.role === 0 || userInfo.role === 1 ? (
          <div className="mt-4 ">
            <div
              className={`my-3 flex justify-between ${
                i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              <p style={blacktext} className="text-[14px] font-[600]">
                {t("empOverview")}
              </p>
              <button onClick={handleRefresh}>
                <span
                  style={blacktext}
                  className="material-symbols-outlined !text-[16px] font-[700]"
                >
                  refresh
                </span>
              </button>
            </div>
            <div className="h-[300px]">
              {" "}
              <CeoCard isRefresh={isRefresh} />
            </div>{" "}
          </div>
        ) : null} */
}

{
  /* <div className="mt-4 h-">
<div
  className={`my-3 flex justify-between  ${
    i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
  }`}
>
  <p style={blacktext} className="text-[14px] font-[600]">
    {t("allmytasks")}
  </p>
  <Link to="/home/task">
    <p style={bluetext} className="  font-[600] cursor-pointer ">
      {t("seemore")}
    </p>
  </Link>
</div>
<RecentTask reloadCounter={reloadCounter} />
</div>
<div className="mt-4">
<div
  className={`my-3 flex justify-between ${
    i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
  }`}
>
  <p style={blacktext} className="text-[14px] font-[600]">
    {t("meetingforToday")}
  </p>
  <Link to="/home/meeting">
    <p style={bluetext} className="  font-[600] cursor-pointer ">
      {t("seemore")}
    </p>
  </Link>
</div>
{meetingInfo.length > 0 ? (
  <>
    <div
      className={`flex gap-4 xl:gap-10 scroll-container ${
        i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
      }`}
    >
      {meetingInfo.map((info, index) => (
        <MeetingCard
          meetingInfos={info}
          key={index}
          refresh={handleRefresh}
        />
      ))}
    </div>
  </>
) : (
  <p
    style={blacktext}
    className="text-[14px] text-center text-gray-500"
  >
    {t("noactionreq")}
  </p>
)}
</div>

{userInfo.role === 1 || userInfo.role === 2 || userInfo.role === 0 ? (
<div className="mt-4 ">
  <div
    className={`my-3 hidden md:flex justify-between ${
      i18n.language === "ar" ? "text-right" : "text-left"
    } ${i18n.language === "ar" ? "rtl" : "ltr"}`}
    dir={i18n.language === "ar" ? "rtl" : "ltr"}
  >
    <p style={blacktext} className=" font-[600]">
      {t("projects")}
    </p>
    <Link to="/home/overallinprogressproject">
      <p style={bluetext} className="  font-[600] cursor-pointer ">
        {t("seemore")}
      </p>{" "}
    </Link>
  </div>
  <CeoOverallproject reloadCounter={reloadCounter} />
</div>
) : null} */
}
