//     // const localAudioElement = document.createElement("audio");
//     //   localAudioElement.srcObject = localStream.current;
//     //   localAudioElement.autoplay = true;
//     //   document.body.appendChild(localAudioElement);

import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { io } from "socket.io-client";


// https://mandoobee.azurewebsites.net

// ws://40.119.179.70:3000

// http://192.168.0.100:3000

const socket = io("https://mandoobee.azurewebsites.net", {
  transports: ["websocket"],
});


console.log(socket.id,"jnjjnj")

socket.on("connect", () => {
  console.log("Connected to WebSocket server");
  console.log("Socket ID:", socket.id); // Correctly logs the socket ID after connection

});

socket.on("connect_error", (err) => {
  console.error("Connection error:", err);
});
const WebRTCApp = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [roomId, setRoomId] = useState("007");
  const [userId, setUserId] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [users, setUsers] = useState([]);
  const [isJoined, setIsJoined] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [username, setUsername] = useState("");
  const [isUsernamePromptVisible, setIsUsernamePromptVisible] = useState(false);
  const localStream = useRef(null);
  const peerConnections = useRef({});
  const streams = useRef({});
  const [mutePrompts, setMutePrompts] = useState({});
  const [isParticipantsOpen, setIsParticipantsOpen] = useState(false);

  const getInitials = (userName) => {
    if (!userName) return "U";
    const names = userName.split(" ");
    const initials =
      names[0]?.charAt(0).toUpperCase() + (names[1]?.charAt(0).toUpperCase() || "");
    return initials || "U";
  };

  const createPeerConnection = (roomId) => {
    console.log("soketid",roomId)
    const pc = new RTCPeerConnection({
      iceServers: [
        { urls: "stun:stun.l.google.com:19302" },
        { urls: "stun:stun1.l.google.com:19302" },
        // {
        //    urls:"turn:40.119.179.70:3478", // "turn:relay.backups.cz"
        //   username:  "azureuser", //"webrtc",
        //   credential: "meerana@1994" //"webrtc"
        // }
      ]
    });
    pc.iceCandidateQueue = [];


    if (localStream.current) {
      localStream.current.getTracks().forEach((track) => {
        pc.addTrack(track, localStream.current);
      });
    }

    pc.onicecandidate = ({ candidate }) => {
      if (candidate) {
        console.log("Sending ICE candidate:", candidate);
        socket.emit("ice-candidate", { candidate, roomId });
      }
    };
    

    pc.ontrack = (event) => {
      const [remoteStream] = event.streams;
      let audioElement = document.getElementById(`audio-${roomId}`);
      if (!audioElement) {
        audioElement = document.createElement("audio");
        audioElement.id = `audio-${roomId}`;
        audioElement.autoplay = true;
        document.body.appendChild(audioElement);
      }
      audioElement.srcObject = remoteStream;
    };

    peerConnections.current[roomId] = pc;
    return pc;
  };


  const processAudio = (stream) => {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const destination = audioContext.createMediaStreamDestination();

    const highPassFilter = audioContext.createBiquadFilter();
    highPassFilter.type = "highpass";
    highPassFilter.frequency.value = 100;

    const gainNode = audioContext.createGain();
    gainNode.gain.value = 1;

    source.connect(highPassFilter);
    highPassFilter.connect(gainNode);
    gainNode.connect(destination);

    return destination.stream;
  };




  const addNotification = (message) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message }]);

    setTimeout(() => {
      setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    }, 2000);
  };

  // const checkSpeaking = (user, stream) => {
  //   const audioContext = new AudioContext();
  //   const analyser = audioContext.createAnalyser();
  //   const source = audioContext.createMediaStreamSource(stream);

  //   analyser.fftSize = 256;
  //   const dataArray = new Uint8Array(analyser.frequencyBinCount);
  //   source.connect(analyser);

  //   const updateSpeakingStatus = () => {
  //     analyser.getByteFrequencyData(dataArray);
  //     const volume = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;

  //     const isSpeaking = volume > 10; 

  //     setUsers((prevUsers) =>
  //       prevUsers.map((u) => {
  //         if (u.userId === user.userId) {
  //           if (u.isMuted && isSpeaking) {
  //             setMutePrompts((prev) => ({
  //               ...prev,
  //               [u.userId]: true,
  //             }));

  //             setTimeout(() => {
  //               setMutePrompts((prev) => ({
  //                 ...prev,
  //                 [u.userId]: false,
  //               }));
  //             }, 3000);
  //           }
  //           return { ...u, isSpeaking: isSpeaking && !u.isMuted };
  //         }
  //         return u;
  //       })
  //     );

  //     requestAnimationFrame(updateSpeakingStatus);
  //   };

  //   updateSpeakingStatus();
  // };

const checkSpeaking = (user, stream) => {
  if (!(stream instanceof MediaStream)) {
    console.error("Invalid stream: parameter is not a MediaStream.");
    return;
  }

  const audioContext = new AudioContext();
  const analyser = audioContext.createAnalyser();
  const source = audioContext.createMediaStreamSource(stream);

  analyser.fftSize = 256;
  const dataArray = new Uint8Array(analyser.frequencyBinCount);
  source.connect(analyser);

  const updateSpeakingStatus = () => {
    analyser.getByteFrequencyData(dataArray);
    const volume = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;

    const isSpeaking = volume > 10;

    setUsers((prevUsers) =>
      prevUsers.map((u) => {
        if (u.userId === user.userId) {
          if (u.isMuted && isSpeaking) {
            setMutePrompts((prev) => ({
              ...prev,
              [u.userId]: true,
            }));

            setTimeout(() => {
              setMutePrompts((prev) => ({
                ...prev,
                [u.userId]: false,
              }));
            }, 3000);
          }
          return { ...u, isSpeaking: isSpeaking && !u.isMuted };
        }
        return u;
      })
    );

    requestAnimationFrame(updateSpeakingStatus);
  };

  updateSpeakingStatus();
};


  const joinRoom = async () => {
    // addNotification(`${users.userName} joined the meeting`);

    try {
      const rawStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          sampleRate: 48000,
        },
        video: false,
      });



      localStream.current = processAudio(rawStream);

      const newUrl = `${window.location.origin}${window.location.pathname}?roomId=${roomId}&userId=${userId}`;
      window.history.pushState(null, "", newUrl);

      console.log("Processed audio stream ready.");
      socket.emit("join-room", { roomId, userId, isMuted, socketId: socket.id });
      setIsJoined(true);
    } catch (error) {
      console.error("Error accessing media devices:", error);
      alert("Could not access microphone.");
    }
  };







  const leaveRoom = () => {
    try {
      Object.values(peerConnections.current).forEach((pc) => {
        pc.close();
      });
      peerConnections.current = {};

      socket.emit("leave-room", { userId });

      if (localStream.current) {
        localStream.current.getTracks().forEach((track) => {
          track.stop();
        });
        localStream.current = null;
      }

      const audioElements = document.querySelectorAll("audio[id^='audio-']");
      audioElements.forEach((audio) => audio.remove());

      if (window.AudioContext) {
        const audioContext = new AudioContext();
        audioContext.close().then(() => {
          console.log("AudioContext closed successfully.");
        });
      }

      setIsJoined(false);
      addNotification(`${users.userName} Left the meeting`);

      setUsers([]);


      const newUrl = `${window.location.origin}${window.location.pathname}`;
      window.history.pushState(null, "", newUrl);

      window.location.reload();

      console.log("Left the room and cleaned up resources");
    } catch (error) {
      console.error("Error while leaving room and cleaning up resources:", error);
    }
  };




  const toggleMute = async () => {
    const audioTrack = localStream.current?.getAudioTracks()[0];
    if (audioTrack) {
      audioTrack.enabled = !audioTrack.enabled;
      const newIsMuted = !audioTrack.enabled; 
      setIsMuted(newIsMuted); //
      // setIsMuted(!audioTrack.enabled);
      console.log(`Mic is ${audioTrack.enabled ? "unmuted" : "muted"}`);
      socket.emit("mic-state-changed", { userId, isMuted: !audioTrack.enabled });
console.log({isMuted: !audioTrack.enabled },"nbhbh")
      if (audioTrack.enabled) {
        console.log("Recreating peer connections after unmuting... llll");

        Object.values(peerConnections.current).forEach((pc) => pc.close());
        peerConnections.current = {};

        for (const user of users) {
          if (user.socketId !== socket.id) {
            console.log(user.socketId,"cc")
            const pc = createPeerConnection(user.socketId);
            const offer = await pc.createOffer();
            await pc.setLocalDescription(offer);
            console.log("Sending new offer to vvvv:", user.socketId);
            socket.emit("offer", { offer, roomId: user.socketId });
          }
        }
      }
    }
  };

  // const toggleMute = () => {
  //   if (localStream.current) {
  //     const audioTrack = localStream.current.getAudioTracks()[0];
  //     if (audioTrack) {
  //       audioTrack.enabled = !audioTrack.enabled; // Toggle the audio track state
  //       const newIsMuted = !audioTrack.enabled; // Determine the new mute state
  //       setIsMuted(newIsMuted); // Update the state
  
  //       console.log(`Mic is now ${newIsMuted ? "muted" : "unmuted"}`);
        
  //       // Notify the server of the mic state change
  //       socket.emit("mic-state-changed", { userId, isMuted: newIsMuted });
  //       console.log("mmmm")
  //     } else {
  //       console.error("No audio track found to toggle mute state.");
  //     }
  //   } else {
  //     console.error("No local stream available to toggle mute state.");
  //   }
  // };
  

  useEffect(() => {
    const handleAllUsers = async (usersList) => {
      console.log("All users in room:", usersList);
    
      setUsers((prevUsers) => {
        const newUsers = usersList.filter(
          (newUser) => !prevUsers.some((user) => user.userId === newUser.userId)
        );
    
        newUsers.forEach((newUser) => {
          addNotification(`${newUser.userName || "Unknown"} joined the meeting`);
    
          // if (localStream.current && newUser.stream && !newUser.isMuted) {
            console.log(`Enabling speaking detection for ${newUser.userName || "Unknown"}`);
            setIsMuted(newUser.isMuted)
            checkSpeaking(newUser, newUser.stream);
          // }
        });
    
        return [...prevUsers, ...newUsers];
      });
    
      for (const user of usersList) {
        if (user.socketId !== socket.id) {
          const pc = createPeerConnection(user.socketId);
          const offer = await pc.createOffer();
          await pc.setLocalDescription(offer);
          console.log("Sending offer to:", user.socketId);
          socket.emit("offer", { offer, roomId: user.socketId });
        }
      }
    };
    


    const handleUserJoined = (user) => {
      console.log("User joined:", user);
      setUsers((prevUsers) => {
        const exists = prevUsers.some((u) => u.userId === user.userId);
        if (exists) return prevUsers;
        return [...prevUsers, user];
      });
    };

    const handleUserLeft = ({ userId, socketId }) => {
      console.log(`User left: ${userId}`);
      if (peerConnections.current[socketId]) {
        peerConnections.current[socketId].close();
        delete peerConnections.current[socketId];
      }
      setUsers((prevUsers) => prevUsers.filter((user) => user.userId !== userId));
    };


    // const handleMicStateChanged = ({ userId, isMuted }) => {
    //   console.log(`Mic state changed for ${userId}: ${isMuted ? "Muted" : "Unmuted"}`);
    //   setUsers((prevUsers) =>
    //     prevUsers.map((user) =>
    //       user.userId === userId ? { ...user, isMuted } : user
    //     )
    //   );
    //   console.log("Updated users:", users);

    // };

    const handleMicStateChanged = ({ userId, isMuted }) => {
      console.log(`Mic state changed for ${userId}: ${isMuted ? "Muted" : "Unmuted"}`);
    
      setUsers((prevUsers) => {
        const updatedUsers = prevUsers.map((user) =>
          user.userId === userId ? { ...user, isMuted } : user
        );
        console.log("Updated users within setUsers callback:", updatedUsers);
        return updatedUsers;
      });
    };
    
    const handleOffer = async ({ offer, from }) => {
      console.log("Received offer:", offer);
      let pc = peerConnections.current[from] || createPeerConnection(from);

      try {
        if (pc.signalingState !== "stable") {
          console.warn("Signaling state not stable, rolling back...");
          await pc.setRemoteDescription({ type: "rollback" });
        }

        await pc.setRemoteDescription(new RTCSessionDescription(offer));
        console.log("Remote description set for offer.");
        pc.iceCandidateQueue = [];

        const answer = await pc.createAnswer();
        await pc.setLocalDescription(answer);
        console.log("Sending answer:", answer);
        socket.emit("answer", { answer, roomId: from });
      } catch (error) {
        console.error("Error processing offer:", error);
      }
    };



    const handleAnswer = async ({ answer, from }) => {
      console.log("Received Answer:", answer);
      const pc = peerConnections.current[from];
      if (!pc) {
        console.error("PeerConnection not found for", from);
        return;
      }

      if (pc.signalingState !== "have-local-offer") {
        console.warn("Ignoring answer: PeerConnection is not in 'have-local-offer' state.");
        return;
      }

      try {
        await pc.setRemoteDescription(new RTCSessionDescription(answer));
        console.log("Remote description set for answer.");
      } catch (error) {
        console.error("Error setting remote description for answer:", error);
      }
    };








    const handleIceCandidate = ({ candidate, from }) => {
      console.log("Received ICE candidate from:", from);
      console.log(candidate, "ice")
      const pc = peerConnections.current[from];

      if (pc) {
        if (pc.remoteDescription) {
          pc.addIceCandidate(new RTCIceCandidate(candidate))
            .then(() => console.log("ICE Candidate added successfully"))
            .catch((err) => console.error("Error adding ICE candidate:", err));
        } else {
          console.warn("Remote description not set, queuing ICE candidate.");
          pc.iceCandidateQueue.push(candidate);
        }
      }
    };





    socket.on("all-users", handleAllUsers);
    socket.on("user-joined", handleUserJoined);
    socket.on("user-left", handleUserLeft);
    socket.on("mic-state-changed", handleMicStateChanged);
    socket.on("offer", handleOffer);
    socket.on("answer", handleAnswer);
    socket.on("ice-candidate", handleIceCandidate);

    return () => {
      socket.off("all-users", handleAllUsers);
      socket.off("user-joined", handleUserJoined);
      socket.off("user-left", handleUserLeft);
      socket.off("mic-state-changed", handleMicStateChanged);
      socket.off("offer", handleOffer);
      socket.off("answer", handleAnswer);
      socket.off("ice-candidate", handleIceCandidate);
    };
  }, [roomId]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(`${baseUrl}/user/userDetails`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUserId(data.result.userId);
      })
      .catch(console.error);
  }, []);


  const startLocalSpeakingDetection = () => {
    if (localStream.current) {
      checkSpeaking(
        { userId, userName: "You", isMuted },
        localStream.current
      );
    }
  };

  useEffect(() => {
    if (isJoined && !isMuted) {
      startLocalSpeakingDetection();
    }
  }, [isJoined, isMuted]);




   useEffect(() => {
    // Logging `socket.id` after connection
    socket.on("connect", () => {
      console.log("Socket connected:", socket.id); // Ensure `socket.id` is available
    });

    return () => {
      socket.off("connect");
    };
  }, []);
  return (




    

    <div className="min-h-screen bg-gray-50 flex flex-col">
    <header className="bg-white shadow-md fixed top-0 w-full z-50 flex items-center justify-between px-4 lg:px-8 py-3">
      <NavLink to="/home">
        <h1 className="text-lg sm:text-2xl font-bold text-[#252C58]">Mandoobee Audio Meet</h1>
      </NavLink>
    </header>

    {/* Main Content */}
    <main className="flex-1 pt-20 px-4 lg:px-8 flex flex-col lg:flex-row items-start">

      <div className="fixed top-4 right-4 z-50">
        {notifications.map(({ id, message }) => (
          <div
            key={id}
            className="mb-2 p-3 bg-[#1552B2] text-white rounded-lg shadow-lg animate-fade-in-out"
          >
            {message}
          </div>
        ))}
      </div>
      <div className="w-full lg:w-3/4 flex flex-col mb-12 sm:mb-16 lg:mb-20 p-4 lg:p-10 space-y-4 lg:space-y-6">
        {!isJoined && (
          <>
          <div className="mt-20 p-10">
            <h2 className="text-xl sm:text-3xl lg:text-5xl font-semibold text-gray-800">
              Join or Start a Meeting
            </h2>
            <p className="text-gray-600 text-sm py-6 sm:text-lg">
              Connect with others seamlessly using our WebRTC service.
            </p>
            <div className="flex flex-col sm:flex-row  gap-4 w-full">
              <button
                onClick={joinRoom}
                className="bg-[#252C58] text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg hover:bg-[#252C58] transition"
              >
                Start New Meeting
              </button>
              <div className="flex items-center border rounded-lg overflow-hidden w-full sm:w-auto">
                <input
                  type="text"
                  className="p-2 sm:p-3 flex-1 outline-none text-sm sm:text-base"
                  placeholder="Enter Room ID"
                  value={roomId}
                  onChange={(e) => setRoomId(e.target.value)}
                />
                <button
                  onClick={joinRoom}
                  className="bg-[#252C58] text-white px-3 py-2 sm:px-4 sm:py-2"
                >
                  Join
                </button>
              </div>
            </div>
            </div>
          </>
        )}

        {/* User Grid */}
        {isJoined && (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {users.map((user, index) => (
              <div
                key={`${user.userId}-${index}`}
                className="w-24 h-24 sm:w-32 sm:h-32 bg-gray-800 rounded-lg flex flex-col items-center justify-center shadow-md relative"
              >
                <div className="w-16 h-16 sm:w-20 sm:h-20 rounded-full flex items-center justify-center bg-[#252C58]">
                  {user.userImage ? (
                    <img
                      src={user.userImage}
                      alt={user.userName || "User"}
                      className="w-full h-full rounded-full object-cover"
                    />
                  ) : (
                    <span className="text-white text-lg sm:text-2xl font-bold">
                      {getInitials(user.userName || "Unknown")}
                    </span>
                  )}
                </div>
                {user.isMuted && (
                  <div className="absolute top-0 bg-red-500 text-white text-xs px-2 py-1 rounded">
                    Muted
                  </div>
                )}
                <span className="text-white mt-2 text-sm">{user.userName || "Unknown"}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Sidebar - Participants */}
      {isJoined ? (

<div
className={`fixed top-10 md:top-14 right-0 h-full bg-gray-900 text-white w-64 lg:w-1/4 transform ${
  isParticipantsOpen ? "translate-x-0" : "translate-x-full"
} lg:translate-x-0 transition-transform duration-300 shadow-lg z-40`}
>
<div className="flex justify-between items-center px-4 py-3 border-b border-gray-700">
  <h2 className="text-lg font-bold">Participants</h2>
  <button
    onClick={() => setIsParticipantsOpen(false)}
    className="lg:hidden text-white text-lg font-bold"
  >
    ✕
  </button>
        </div>
        
        <div className="overflow-y-auto h-full px-4 py-2 space-y-2">
          {users.map((user, index) => (
            <div
              key={index}
              className="px-4 py-2 bg-gray-800 rounded-md flex items-center justify-between"
            >
              <span className="text-sm">{user.userName || "Unknown"}</span>
              {user.isMuted && <span className="text-xs text-red-400">Muted</span>}
            </div>
          ))}
        </div>
      </div>
      ):(

<div className="text-center px-6 py-12">
            <img
              src="/assets/Mandoobee_Logo.png"
              alt="Illustration"
              className="max-w-full h-[200px] sm:h-[400px] mx-auto"
            />
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-gray-800 mt-6">
              Get a link that you can share
            </h2>
            <p className="text-gray-600 mt-4 text-sm sm:text-lg">
              Click <strong>New meeting</strong> to get a link that you can send to people that
              you want to meet with.
            </p>
          </div>)}
    </main>

    {isJoined && (
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 flex gap-4 px-4">
        <button
          onClick={leaveRoom}
          className="bg-red-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg hover:bg-red-700 transition"
        >
          Leave Meeting
        </button>
        <button
          onClick={toggleMute}
          className={`px-4 sm:px-6 py-2 sm:py-3 rounded-lg ${
            isMuted ? "bg-red-500" : "bg-[#1552B2]"
          } text-white`}
        >
          {isMuted ? "Unmute" : "Mute"}
        </button>
      </div>
    )}

    {/* Toggle Button for Sidebar */}
    {isJoined && !isParticipantsOpen && (
      <button
        onClick={() => setIsParticipantsOpen(true)}
        className="fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded-full shadow-lg lg:hidden"
      >
        Participants
      </button>
    )}
  </div>

  );
};

export default WebRTCApp;































































  // const joinRoom = async () => {
  //   try {
  //     localStream.current = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //       video: false,
  //     });

  //     // const localAudioElement = document.createElement("audio");
  //     // localAudioElement.srcObject = localStream.current;
  //     // localAudioElement.autoplay = true;
  //     // document.body.appendChild(localAudioElement);

  //     console.log("Local stream acquired: mmm", localStream.current);

  //     console.log(
  //       "Audio Tracks:",
  //       localStream.current.getAudioTracks().map((track) => track.label)
  //     );


  //     socket.emit("join-room", { roomId, userId, isMuted, socketId: socket.id });
  //     setIsJoined(true);
  //   } catch (error) {
  //     console.error("Error accessing media devices:", error);
  //     alert("Could not access microphone.");
  //   }
  // };
  //   <div className=" rounded-2xl p-5 lg:p-0 min-h-[75vh] h-auto">

  //     <div className="fixed top-4 right-4 z-50">
  //       {notifications.map(({ id, message }) => (
  //         <div
  //           key={id}
  //           className="mb-2 p-3 bg-[#1552B2] text-white rounded-lg shadow-lg animate-fade-in-out"
  //         >
  //           {message}
  //         </div>
  //       ))}
  //     </div>

  //     <div className="flex flex-row ">


  //       {/* <div className="w-64 bg-gray-900  text-white flex flex-col shadow-lg">
  //         <h2 className="text-xl font-bold px-4 py-2">Participants</h2>
  //         <div className="relative ">
  //           <button
  //             className="w-full text-left px-4 py-2 bg-gray-800 hover:bg-gray-700 focus:outline-none"
  //             onClick={() => setIsDropdownOpen(!isDropdownOpen)}
  //           >
  //             {isDropdownOpen ? "Hide Participants" : "Show Participants"}
  //           </button>
  //           {isDropdownOpen && (
  //             <ul className="absolute w-full bg-gray-700 rounded-md mt-1  overflow-y-auto z-10">
  //               {users.map((user) => (
  //                 <li
  //                   key={user.userId}
  //                   className="p-2 hover:bg-gray-600 cursor-pointer border-b border-gray-500"
  //                 >
  //                   <div>
  //                     <strong>Name:</strong> {user.userName || "Unknown"}
  //                   </div>
  //                   <div>
  //                     <strong>Muted:</strong> {user.isMuted ? "Yes" : "No"}
  //                   </div>

  //                 </li>
  //               ))}
  //             </ul>
  //           )}
  //         </div>
  //       </div> */}

  //       {isJoined &&
  //         <div className="w-64 bg-gray-900 text-white flex flex-col shadow-lg">
  //           <h2 className="text-xl font-bold px-4 py-2">Participants</h2>
  //           <div className="overflow-y-auto h-full">
  //             {users.map((user, index) => (
  //               <div key={index} className="px-4 py-2 border-b border-gray-700">
  //                 <p className="text-sm">
  //                   <strong>{user.userName || "Unknown"} </strong>
  //                   {user.isMuted ? "joined the meeting (Muted)" : "joined the meeting"}
  //                 </p>
  //               </div>
  //             ))}
  //           </div>
  //         </div>}

  //       <div className="flex-1  w-full h-auto  flex flex-col items-center justify-center">
  //         {!isJoined && <>
  //           <h1 className="text-2xl font-bold mb-10">WebRTC Audio Call</h1>



  //           <input
  //             type="text"
  //             className="p-2 border rounded-md mb-4"
  //             value={roomId}
  //             onChange={(e) => setRoomId(e.target.value)}
  //             placeholder="Enter Room ID"
  //           /></>}
  //         <div className="flex gap-4 mb-4">
  //           {!isJoined ? (
  //             <button
  //               onClick={joinRoom}
  //               className="bg-[#1552B2] text-white px-4 py-2 rounded-md"
  //             >
  //               Join Room
  //             </button>
  //           ) : (
  //             <button
  //               onClick={leaveRoom}
  //               className="bg-red-500 text-white px-4 py-2 rounded-md"
  //             >
  //               Leave Room
  //             </button>
  //           )}
  //           {isJoined &&
  //             <button
  //               onClick={toggleMute}
  //               className={`px-4 py-2 rounded-md ${isMuted ? "bg-red-500 text-white" : "bg-[#1552B2] text-white"
  //                 }`}
  //             >
  //               {isMuted ? "Unmute" : "Mute"}
  //             </button>}
  //         </div>
  //         <div className="grid grid-cols-2 gap-4 mb-60">
  //           {users.map((user, index) => (
  //             <div
  //               key={`${user.userId}-${index}`}
  //               className="w-32 h-32 bg-gray-800 rounded-lg flex flex-col items-center justify-center shadow-md relative"
  //             >
  //               <div className="w-20 h-20 rounded-full flex items-center justify-center bg-[#1552B2]">

  //                 {user.userImage ? (
  //                   <img
  //                     src={user.userImage}
  //                     alt={user.userName || "User"}
  //                     className="w-20 h-20 rounded-full object-cover"
  //                   />
  //                 ) : (
  //                   <div
  //                     className={`flex items-center justify-center rounded-full w-20 h-20 `}
  //                   >
  //                     <span className="text-white text-2xl font-bold">
  //                       {getInitials(user.userName || "Unknown")}
  //                     </span>
  //                   </div>
  //                 )}


  //                 {user.isMuted && (
  //                   <div className="absolute bottom-[100px] bg-red-500 mr-[60px] text-white text-xs px-2 py-1 rounded">
  //                     Muted
  //                   </div>
  //                 )}

  //                 {user.isSpeaking && !user.isMuted && (
  //                   <div className="absolute top-0 right-0  text-white text-xs px-2 py-1 rounded">
  //                     ....
  //                   </div>
  //                 )}


  //                 {mutePrompts[user.userId] && (


  //                   <div className="absolute bottom-[-20px] bg-yellow-500 text-white text-xs px-2 py-1 rounded">
  //                     Unmute to speak
  //                   </div>
  //                 )}
  //               </div>
  //               <span className="text-white mt-2 text-sm">{user.userName || "Unknown"}</span>

  //             </div>

  //           ))}
  //         </div>
  //       </div>
  //     </div>
  //   </div>

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const roomIdParam = searchParams.get("roomId");
  //   const userIdParam = searchParams.get("userId");

  //   if (roomIdParam) setRoomId(roomIdParam);
  //   if (userIdParam) setUserId(userIdParam);

  //   if (roomIdParam && userIdParam) {
  //     joinRoom();
  //   }
  // }, []);