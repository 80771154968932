import React, { useState, useEffect } from "react";
import { Mic, MicOff, Volume2 } from "lucide-react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function Voicebot() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [isListening, setIsListening] = useState(false);
  const [spokenText, setSpokenText] = useState("");
  const [botResponse, setBotResponse] = useState("");
  const [recognition, setRecognition] = useState(null);
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [isCreatingMeet, setIsCreatingMeet] = useState(false);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [isEditingProject, setIsEditingProject] = useState(false);
  const [editField2, setEditField2] = useState("");

  const [isEditingTask, setIsEditingTask] = useState(false);
  const [currentStep, setCurrentStep] = useState("");
  const [editField, setEditField] = useState("");
  const [voiceBotpopup, setVoiceBotpopup] = useState(false);
  const [userData, setUserData] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [showLinkInput, setShowLinkInput] = useState(false);
  // const [selectedEmployees,setSelectedEmployees ] = useState([])
  // const [tempLink, setTempLink] = useState("");

  // leave

  const [currentFlow, setCurrentFlow] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [editField1, setEditField1] = useState("");
  const [isEditingleave, setIsEditingleave] = useState(false);

  // Leave
  const [leaveDetails, setLeaveDetails] = useState({
    leaveType: "",
    isFullDay: null,
    fromDate: "",
    toDate: "",
    startTime: "",
    endTime: "",
    reason: "",
    isVoice: true,
  });

  // /Task
  const [taskDetails, setTaskDetails] = useState({
    title: "",
    description: "",
    assigneeUserId: "",
    assigneeName: "",
    fromDate: "",
    toDate: "",
    fromTime: "",
    toTime: "",
    priority: "",
    isVoice: true,
  });

  // Meeting

  const [meetingDetails, setMeetingDetails] = useState({
    title: "",
    desc: "",
    from: "",
    to: "",
    meetingDate: "",
    isTeam: false,
    link: "",
    notesRef: "",
    attendessRef: [],
    isVoice: true,
  });

  // Project

  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
    projectDes: "",
    clientName: "",
    projectStartDate: "",
    projectEndDate: "",
    projectManagerRef: [],
    projectManagerName: "",
    teamMemberRef: [],
    teamMemberName: [],
    techStack: [],
    isVoice: true,
  });

  const token = localStorage.getItem("token");
  const userInfo = jwtDecode(token);
  const userId = userInfo?.userId;

  const handleOpenPopup = () => {
    setVoiceBotpopup(true);
  };

  const handleClosePopup = () => {
    setVoiceBotpopup(false);

    setCurrentFlow("");
  };

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const recognitionInstance = new window.webkitSpeechRecognition();
      recognitionInstance.continuous = false;
      recognitionInstance.interimResults = false;
      recognitionInstance.lang = "en-US";

      recognitionInstance.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setSpokenText(transcript);

        if (currentFlow === "leave") {
          handleLeaveRequestStep(transcript.trim());
        } else if (isCreatingTask) {
          handleTaskCreationStep(transcript.trim());
        } else if (isCreatingMeet) {
          handleMeetingCreationStep(transcript.trim());
        } else if (isEditingTask) {
          handleTaskEdit(transcript.trim());
        } else if (isCreatingProject) {
          handleProjectCreationStep(transcript.trim());
        } else if (isEditingProject) {
          handleProjectEdit(transcript.trim());
        } else {
          handleInitialCommand(transcript.trim());
        }

        setIsListening(false);
      };

      recognitionInstance.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        setIsListening(false);
      };

      recognitionInstance.onend = () => {
        setIsListening(false);
      };

      setRecognition(recognitionInstance);
    }

    EmployeeDetails();
  }, [
    isCreatingTask,
    isCreatingMeet,
    isEditingTask,
    isCreatingProject,
    currentStep,
    editField,
    currentFlow,
  ]);

  const parseDate = (spokenDate) => {
    const monthNames = [
      "january",
      "february", 
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];

    const cleanedDate = spokenDate.trim().toLowerCase().replace(/\s+/g, " ");
    console.log("Cleaned date input:", cleanedDate);

    const parts = cleanedDate.split(/[\s-]+/);

    if (parts.length >= 2 && isNaN(parts[1])) {
      const day = parseInt(parts[0], 10);
      const monthIndex = monthNames.indexOf(parts[1]);
      if (monthIndex !== -1) {
        const month = monthIndex + 1;
        const year = parts[2]
          ? parseInt(parts[2], 10)
          : new Date().getFullYear();

        if (day > 0 && day <= 31) {
          return `${String(day).padStart(2, "0")}-${String(month).padStart(
            2,
            "0"
          )}-${year}`;
        }
      }
    }

    if (parts.length >= 2) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const year = parts[2] ? parseInt(parts[2], 10) : new Date().getFullYear();

      if (day > 0 && day <= 31 && month > 0 && month <= 12) {
        return `${String(day).padStart(2, "0")}-${String(month).padStart(
          2,
          "0"
        )}-${year}`;
      }
    }

    return null;
  };

  const combineDateTime = (dateStr, timeStr) => {
    if (!dateStr || !timeStr) return null;

    const [day, month, year] = dateStr.split("-");

    const [hours, minutes] = timeStr.split(":");

    const utcDate = new Date(
      Date.UTC(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        parseInt(hours) - 5,
        parseInt(minutes) - 30
      )
    );

    if (utcDate.getMinutes() < 0) {
      utcDate.setMinutes(utcDate.getMinutes() + 60);
      utcDate.setHours(utcDate.getHours() - 1);
    }

    if (utcDate.getHours() < 0) {
      utcDate.setHours(utcDate.getHours() + 24);
      utcDate.setDate(utcDate.getDate() - 1);
    }

    return utcDate.toISOString();
  };

  const handleInitialCommand = (command) => {
    const lowerCommand = command.toLowerCase();
    let response = "";

    if (
      lowerCommand.includes("edit task") ||
      lowerCommand.includes("modify task")
    ) {
      setIsEditingTask(true);

      response = `What would you like to edit? You can say:
          - Edit title
          - Edit description
          - Edit assignee
          - Edit start date
          - Edit end date
          - Edit start time
          - Edit end time
          - Edit priority.`;
    } else if (
      lowerCommand.includes("create project") ||
      lowerCommand.includes("project") ||
      lowerCommand.includes("create a project")
    ) {
      setIsCreatingProject(true);
      setCurrentStep("projectName");
      response = `Let's create a Project. What is the title of the Project?`;
    } else if (
      lowerCommand.includes("create task") ||
      lowerCommand.includes("task") ||
      lowerCommand.includes("create a task")
    ) {
      setIsCreatingTask(true);
      setCurrentStep("title");
      response = "Let's create a task. What is the title of the task?";
    } else if (
      lowerCommand.includes("create meeting") ||
      lowerCommand.includes(" meeting") ||
      lowerCommand.includes("create a meeting")
    ) {
      setIsCreatingMeet(true);
      setCurrentStep("title");
      response = "Let's create a meeting. What is the title of the meeting?";
    } else if (
      lowerCommand.includes("leave request") ||
      lowerCommand.includes("sick leave") ||
      lowerCommand.includes("casual leave") ||
      lowerCommand.includes("work from home")
    ) {
      let detectedLeaveType = "Casual";
      if (lowerCommand.includes("sick leave")) detectedLeaveType = "Sick";
      else if (lowerCommand.includes("work from home"))
        detectedLeaveType = "WFH";

      setLeaveType(detectedLeaveType);
      setCurrentFlow("leave");
      setBotResponse(`Starting your ${detectedLeaveType} leave request.`);
    } else {
      setBotResponse(
        "Sorry, I didn't understand that. You can create tasks or request leaves."
      );
    }

    setBotResponse(response);
    speak(response);
  };

  function parseSpokenTime(timeStr) {
    const cleanedTime = timeStr
      .toLowerCase()
      .trim()
      .replace(/\./g, "")
      .replace(/\s+/g, "");

    const shortTimeRegex = /^(\d{1,2})(am|pm)$/;
    let match = cleanedTime.match(shortTimeRegex);
    if (match) {
      let hour = parseInt(match[1], 10);
      if (match[2] === "pm" && hour !== 12) {
        hour += 12;
      } else if (match[2] === "am" && hour === 12) {
        hour = 0;
      }
      return `${hour.toString().padStart(2, "0")}:00`;
    }

    const militaryTimeRegex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
    match = cleanedTime.match(militaryTimeRegex);
    if (match) {
      const hour = match[1].padStart(2, "0");
      const minutes = match[2];
      return `${hour}:${minutes}`;
    }

    const twelveHourRegex = /^(\d{1,2}):([0-5][0-9])(am|pm)$/;
    match = cleanedTime.match(twelveHourRegex);
    if (match) {
      let hour = parseInt(match[1], 10);
      const minutes = match[2];

      if (match[3] === "pm" && hour !== 12) {
        hour += 12;
      } else if (match[3] === "am" && hour === 12) {
        hour = 0;
      }

      return `${hour.toString().padStart(2, "0")}:${minutes}`;
    }

    return null;
  }

  function levenshteinDistance(a, b) {
    const matrix = Array.from({ length: a.length + 1 }, () =>
      Array(b.length + 1).fill(0)
    );

    for (let i = 0; i <= a.length; i++) {
      for (let j = 0; j <= b.length; j++) {
        if (i === 0) matrix[i][j] = j;
        else if (j === 0) matrix[i][j] = i;
        else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1),
            matrix[i - 1][j] + 1,
            matrix[i][j - 1] + 1
          );
        }
      }
    }

    return matrix[a.length][b.length];
  }

  function findClosestMatch(input, userData) {
    const normalizedInput = input.toLowerCase().replace(/\s+/g, "");
    let closest = null;
    let minDistance = Infinity;

    userData.forEach((emp) => {
      const fullName = emp.empName.toLowerCase();
      const [firstName, lastName] = fullName.split(/\s+/);

      // Calculate distances for full name, first name, and last name
      const fullNameDistance = levenshteinDistance(
        normalizedInput,
        fullName.replace(/\s+/g, "")
      );
      const firstNameDistance = firstName
        ? levenshteinDistance(normalizedInput, firstName)
        : Infinity;
      const lastNameDistance = lastName
        ? levenshteinDistance(normalizedInput, lastName)
        : Infinity;

      // Choose the smallest distance
      const minEmpDistance = Math.min(
        fullNameDistance,
        firstNameDistance,
        lastNameDistance
      );

      if (minEmpDistance < minDistance) {
        minDistance = minEmpDistance;
        closest = emp;
      }
    });

    console.log("Matched Employee:", closest); // Debugging log
    return minDistance <= 2 ? closest : null; // Adjust threshold as needed
  }

  // task

  const handleTaskCreationStep = (command) => {
    let response = "";
    let nextStep = "";

    const updateTaskDetails = (value) => {
      setTaskDetails((prev) => ({
        ...prev,
        [currentStep]: value,
      }));
    };

    switch (currentStep) {
      case "title":
        updateTaskDetails(command);
        nextStep = "description";
        response = `Title set to "${command}". What is the description of the task?`;
        break;

      case "description":
        updateTaskDetails(command);
        nextStep = "assigneeTo";
        response = `Description "${command}" saved. Who do you want to assign this task to?`;
        break;

      case "assigneeTo":
        const matchedEmployee = selectedAssignee
          ? findClosestMatch(selectedAssignee.toLowerCase(), userData)
          : findClosestMatch(command.toLowerCase().trim(), userData);

        if (matchedEmployee) {
          console.log("Matched Employee Details:", matchedEmployee);
          setTaskDetails((prev) => ({
            ...prev,
            assigneeUserId: matchedEmployee.empUserId,
            assigneeName: matchedEmployee.empName,
          }));
          nextStep = "fromDate";
          response = `Task will be assigned to "${matchedEmployee.empName}". What is the start date for this task?`;
          setSelectedAssignee("");
        } else {
          nextStep = "assigneeTo";
          response =
            "Sorry, I couldn't find that employee. Please choose from the dropdown or say their name clearly.";
        }
        break;

      case "fromDate":
        const startDate = parseDate(command);
        if (startDate) {
          updateTaskDetails(startDate);
          nextStep = "toDate";
          response = `Start date set to ${startDate}. What is the end date for this task?`;
        } else {
          nextStep = "fromDate";
          response =
            "Sorry, I couldn't understand the date. Please say the date clearly.";
        }
        break;

      case "toDate":
        const endDate = parseDate(command);
        if (endDate) {
          updateTaskDetails(endDate);
          nextStep = "fromTime";
          response = `End date set to ${endDate}. What time should the task start?`;
        } else {
          nextStep = "toDate";
          response =
            "Sorry, I couldn't understand the date. Please say the date clearly.";
        }
        break;

      case "fromTime":
        const parsedStartTime = parseSpokenTime(command);
        if (parsedStartTime) {
          updateTaskDetails(parsedStartTime);
          nextStep = "toTime";
          response = `Start time set to ${parsedStartTime}. What time should the task end?`;
        } else {
          nextStep = "fromTime";
          response =
            "Sorry, I couldn't understand the time. Please say the time clearly.";
        }
        break;

      case "toTime":
        const parsedEndTime = parseSpokenTime(command);
        if (parsedEndTime) {
          updateTaskDetails(parsedEndTime);
          nextStep = "priority";
          response = `End time set to ${parsedEndTime}. What is the priority level? Say High, Medium, or Low.`;
        } else {
          nextStep = "toTime";
          response =
            "Sorry, I couldn't understand the time. Please say the time clearly.";
        }
        break;

      case "priority":
        const priority = command.toLowerCase().replace(/\./g, "");
        if (["high", "medium", "low"].includes(priority)) {
          updateTaskDetails(priority);
          nextStep = "confirmation";
          response = `Priority set to ${priority}. Here is the task summary:
              - Title: ${taskDetails.title}
              - Description: ${taskDetails.description}
              - Assigned to: ${taskDetails.assigneeName}
              - Dates: ${taskDetails.fromDate} to ${taskDetails.toDate}
              - Times: ${taskDetails.fromTime} to ${taskDetails.toTime}
              - Priority: ${priority}
              
              Say 'yes' to submit the task or 'edit' to make changes.`;
        } else {
          nextStep = "priority";
          response =
            "Please specify a valid priority level: High, Medium, or Low.";
        }
        break;

      case "confirmation":
        if (command.toLowerCase().replace(/\./g, "") === "yes") {
          submitTaskDetails();
          response = "Task has been submitted successfully!";
          setIsCreatingTask(false);
          nextStep = "";
        } else if (command.toLowerCase().replace(/\./g, "") === "edit") {
          response =
            "What would you like to edit? Title, description, assignee, dates, times, or priority?";
          nextStep = "";
          setIsCreatingTask(false);
          setIsEditingTask(true);
        } else {
          response =
            "Please say 'yes' to submit the task or 'edit' to make changes.";
        }
        break;

      default:
        response = "An error occurred. Please try again.";
        setIsCreatingTask(false);
        nextStep = "";
    }

    setCurrentStep(nextStep);
    setBotResponse(response);
    speak(response);
  };

  const submitTaskDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const fromDateTime = combineDateTime(
        taskDetails.fromDate,
        taskDetails.fromTime
      );
      const toDateTime = combineDateTime(
        taskDetails.toDate,
        taskDetails.toTime
      );

      const response = await axios.post(
        `${baseUrl}/task/addTask`,
        {
          title: taskDetails.title,
          desc: taskDetails.description,
          assignUserId: taskDetails.assigneeUserId,
          assigneeName: taskDetails.assigneeName,
          from: fromDateTime,
          to: toDateTime,
          priority: taskDetails.priority,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const successMessage = "Task has been successfully updated and saved!";
      console.log("API Response:", response.data);
      setBotResponse(successMessage);
      speak(successMessage);
    } catch (error) {
      console.error("Error submitting task details:", error);
      const errorMessage =
        "An error occurred while saving the task. Please try again.";
      setBotResponse(errorMessage);
      speak(errorMessage);
    }
  };

  const handleTaskEdit = (command) => {
    const lowerCommand = command.toLowerCase();
    let response = "";

    if (!editField) {
      if (lowerCommand.includes("title")) {
        setEditField("title");
        response = `Current title is "${taskDetails.title}". Please say the new title.`;
      } else if (lowerCommand.includes("description")) {
        setEditField("description");
        response = `Current description is "${taskDetails.description}". Please say the new description.`;
      } else if (lowerCommand.includes("assignee", "name")) {
        setEditField("assigneeName");
        response = `Current assignee is "${taskDetails.assigneeName}". Please say the new assignee.`;
      } else if (lowerCommand.includes("start date")) {
        setEditField("fromDate");
        response = `Current start date is "${taskDetails.fromDate}". Please say the new start date in DD MM format.`;
      } else if (lowerCommand.includes("end date")) {
        setEditField("toDate");
        response = `Current end date is "${taskDetails.toDate}". Please say the new end date in DD MM format.`;
      } else if (lowerCommand.includes("start time")) {
        setEditField("fromTime");
        response = `Current start time is "${taskDetails.fromTime}". Please say the new time in 12-hour format with AM/PM.`;
      } else if (lowerCommand.includes("end time")) {
        setEditField("toTime");
        response = `Current end time is "${taskDetails.toTime}". Please say the new time in 12-hour format with AM/PM.`;
      } else if (lowerCommand.includes("priority")) {
        setEditField("priority");
        response = `Current priority is "${taskDetails.priority}". Please say the new priority (High, Medium, or Low).`;
      } else if (
        lowerCommand.includes("done") ||
        lowerCommand.includes("finish")
      ) {
        setIsEditingTask(false);
        submitTaskDetails(); // Call the API submission function
        response = "Editing completed. Task details have been submitted.";
      } else {
        response =
          "Please specify what you want to edit (title, description, assignee, dates, times, or priority).";
      }
    } else {
      let isValid = true;
      let newValue = command;

      if (editField === "fromDate" || editField === "toDate") {
        newValue = parseDate(command);
        isValid = newValue !== null;
      } else if (editField === "fromTime" || editField === "toTime") {
        isValid = parseSpokenTime(command) !== null;
        if (isValid) {
          newValue = command.toUpperCase();
        }
      } else if (editField === "priority") {
        isValid = ["highest", "medium", "lowest"].includes(lowerCommand);
        newValue = lowerCommand;
      }

      if (isValid) {
        setTaskDetails((prev) => ({
          ...prev,
          [editField]: newValue,
        }));
        response = `Updated ${editField} to "${newValue}". What else would you like to edit? Say "done" when finished.`;
        setEditField("");
      } else {
        response = "Invalid input. Please try again.";
      }
    }

    setBotResponse(response);
    speak(response);
  };

  const toggleListening = () => {
    if (isListening) {
      recognition?.stop();
    } else {
      recognition?.start();
    }
    setIsListening(!isListening);
  };

  const speak = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  async function EmployeeDetails() {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${baseUrl}/user/employeeDetails`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });

      if (response) {
        setUserData(response?.data.result);
        console.log(response, "voice bot");
      } else {
        throw new Error("Failed to fetch employeedetails");
      }
    } catch (error) {
      console.error("Error fetching employeedetails:", error);
    }
  }

  // meet

  const handleMeetingCreationStep = (command) => {
    let response = "";
    let nextStep = "";

    const updateMeetingDetails = (value) => {
      setMeetingDetails((prev) => ({
        ...prev,
        [currentStep]: value,
      }));
    };

    switch (currentStep) {
      case "title":
        updateMeetingDetails(command);
        nextStep = "description";
        response = `Meeting title set to "${command}". What is the description of the meeting?`;
        break;

      case "description":
        updateMeetingDetails(command);
        nextStep = "meetingDate";
        response = `Description set to "${command}". What date would you like to schedule the meeting for?`;
        break;

      case "meetingDate":
        const meetDate = parseDate(command);
        if (meetDate) {
          updateMeetingDetails(meetDate);
          nextStep = "fromTime";
          response = `Meeting date set to ${meetDate}. What time should the meeting start?`;
        } else {
          nextStep = "meetingDate";
          response = `Sorry, I couldn't understand the date. Please say the date clearly.`;
        }
        break;

      case "fromTime":
        const parsedStartTime = parseSpokenTime(command);
        if (parsedStartTime) {
          // Explicitly set 'from' instead of using current step
          setMeetingDetails((prev) => ({
            ...prev,
            from: parsedStartTime,
          }));
          nextStep = "toTime";
          response = `Start time set to ${parsedStartTime}. What time should the meeting end?`;
        } else {
          nextStep = "fromTime";
          response = `Sorry, I couldn't understand the time. Please say the time clearly`;
        }
        break;

      case "toTime":
        const parsedEndTime = parseSpokenTime(command);
        if (parsedEndTime) {
          // Explicitly set 'to' instead of using current step
          setMeetingDetails((prev) => ({
            ...prev,
            to: parsedEndTime,
          }));
          nextStep = "attendeesRef";
          response = `End time set to ${parsedEndTime}. Who would you like to invite to this meeting?`;
        } else {
          nextStep = "toTime";
          response = `Sorry, I couldn't understand the time. Please say the time clearly`;
        }
        break;

      case "attendessRef":
        const attendeeNames = command.split("and").map((name) => name.trim());
        const matchedAttendees = attendeeNames
          .map((name) => findClosestMatch(name, userData))
          .filter(Boolean);

        if (matchedAttendees.length > 0) {
          const attendeeRefs = matchedAttendees.map((emp) => emp.empUserId);
          updateMeetingDetails(attendeeRefs);
          nextStep = "notesTaker";
          response = `Added ${matchedAttendees
            .map((emp) => emp.empName)
            .join(
              ", "
            )} to the meeting. Who should take notes for this meeting?`;
        } else {
          nextStep = "attendessRef";
          response =
            "Sorry, I couldn't find those employees. Please try again.";
        }
        break;

      case "notesTaker":
        const notesTaker = findClosestMatch(command, userData);
        if (notesTaker) {
          // Explicitly set 'notesRef'
          setMeetingDetails((prev) => ({
            ...prev,
            notesRef: notesTaker.empUserId,
          }));
          nextStep = "link";
          response = `${notesTaker.empName} will take notes. Please provide the meeting link.`;
        } else {
          nextStep = "notesTaker";
          response = "Sorry, I couldn't find that employee. Please try again.";
        }
        break;

      case "link":
        if (command === "VOICE_INPUT_DISABLED") {
          setShowLinkInput(true);
          nextStep = "link";
          response = "Please enter the meeting link in the input box below.";
        } else if (showLinkInput) {
          const isValidLink =
            command.includes("meet.google.com") || command.includes("zoom.us");
          if (isValidLink) {
            // Find attendees before creating the summary
            const currentAttendees = meetingDetails.attendeesRef
              ? userData.filter((user) =>
                  meetingDetails?.attendeesRef.includes(user.empUserId)
                )
              : [];

            setMeetingDetails((prev) => ({
              ...prev,
              link: command,
              title: prev.title || "Untitled Meeting",
              desc: prev.desc || "No description",
              from: prev.from || "Not specified",
              to: prev.to || "Not specified",
              meetingDate: prev.meetingDate || "Not specified",
              notesRef: prev.notesRef || "",
              attendeesRef: prev.attendessRef || [],
            }));

            setShowLinkInput(false);
            nextStep = "confirmation";
            response = `Meeting link saved. Here's the meeting summary:
                Title: ${meetingDetails.title}
                Description: ${meetingDetails.desc}
                Date: ${meetingDetails.meetingDate}
                Time: ${meetingDetails.from} to ${meetingDetails.to}
                Attendees: ${
                  currentAttendees.map((emp) => emp.empName).join(", ") ||
                  "None"
                }
                Notes Taker: ${
                  userData.find(
                    (emp) => emp.empUserId === meetingDetails.notesRef
                  )?.empName || "Not assigned"
                }
                Link: ${command}
                
                Should I schedule this meeting?`;
          } else {
            nextStep = "link";
            response =
              "Please provide a valid meeting link (Google Meet or Zoom).";
          }
        }
        break;

      case "confirmation":
        if (command.toLowerCase().includes("yes")) {
          submitMeetingDetails();
          setIsCreatingMeet(false);
          nextStep = "";
          response = "Meeting has been scheduled successfully!";
        } else if (command.toLowerCase().includes("no")) {
          setIsCreatingMeet(false);
          nextStep = "";
          response = "Meeting creation cancelled.";
        } else {
          nextStep = "confirmation";
          response = "Please say 'yes' to confirm or 'no' to cancel.";
        }
        break;

      default:
        response = "An error occurred. Please try again.";
        setIsCreatingMeet(false);
        nextStep = "";
    }

    setCurrentStep(nextStep);
    setBotResponse(response);
    speak(response);
  };

  const submitMeetingDetails = ()=>{
    try {
      fetch(`${baseUrl}/meeting/addMeeting`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization : "Bearer "+ token
        },body : JSON.stringify({
          title: meetingDetails.title,
          desc: meetingDetails.desc,
          from: meetingDetails.from,
          to: meetingDetails.to,
          meetingDate: meetingDetails.meetingDate,
          isTeam: false,
          link: meetingDetails.link,
          attendessRef: meetingDetails.attendessRef,
          notesRef: meetingDetails.notesRef,        
        })
      }).then((response)=>{
        if(!response.ok){
          throw new Error(response.statusText);
        }
        return response.json();
      }).then((data)=>{
        console.log(data);
        setIsCreatingMeet(false);
      }).catch((err)=>{
        console.error(err);
      })
    } catch (error) {
      console.log();
    }
  }

  //Project
  const handleProjectCreationStep = (command) => {
    let response = "";
    let nextStep = currentStep;

    console.log("User Input:", command);
    console.log("Current Step Before:", currentStep);
    console.log("Current Project Details:", projectDetails);

    switch (currentStep) {
      case "projectName":
        setProjectDetails((prev) => ({
          ...prev,
          projectName: command,
        }));
        nextStep = "projectdes";
        response = `Project title set to "${command}". What is the description of the Project?`;
        break;

      case "projectdes":
        setProjectDetails((prev) => ({
          ...prev,
          projectDes: command,
        }));
        nextStep = "clientName";
        response = `Description set to "${command}". What is the ClientName of the Project?`;
        break;

      case "clientName":
        setProjectDetails((prev) => ({
          ...prev,
          clientName: command,
        }));
        nextStep = "projectStartDate";
        response = `ClientName set to "${command}". What is the Start Date of the Project?`;
        break;

      case "projectStartDate":
        const projectStartDate = parseDate(command);
        if (projectStartDate) {
          setProjectDetails((prev) => ({
            ...prev,
            projectStartDate: projectStartDate,
          }));
          nextStep = "projectEndDate";
          response = `Start date set to ${projectStartDate}. What is the end Date of the Project?`;
        } else {
          nextStep = "projectStartDate";
          response = `Sorry, I couldn't understand the date. Please say the date clearly.`;
        }
        break;

      case "projectEndDate":
        const projectEndDate = parseDate(command);
        if (projectEndDate) {
          setProjectDetails((prev) => ({
            ...prev,
            projectEndDate: projectEndDate,
          }));
          nextStep = "projectManagerRef";
          response = `End date set to ${projectEndDate}. Who is the project Manager of the Project?`;
        } else {
          nextStep = "projectEndDate";
          response = `Sorry, I couldn't understand the date. Please say the date clearly.`;
        }
        break;

      case "projectManagerRef":
        const matchedEmployee = selectedAssignee
          ? findClosestMatch(selectedAssignee.toLowerCase(), userData)
          : findClosestMatch(command.toLowerCase().trim(), userData);

        if (matchedEmployee) {
          console.log("Matched Manager Details:", matchedEmployee);
          setProjectDetails((prev) => ({
            ...prev,
            projectManagerRef: matchedEmployee.empUserId,
            projectManagerName: matchedEmployee.empName,
          }));
          nextStep = "teamMemberRef";
          response = `Project manager set to "${matchedEmployee.empName}". Who are all the Team Members?`;
          setSelectedAssignee("");
        } else {
          nextStep = "projectManagerRef";
          response =
            "Sorry, I couldn't find that employee. Please choose from the dropdown or say their name clearly.";
        }
        break;

      // In the creation handler, update the teamMemberRef case:
      case "teamMemberRef":
        const memberNames = command
          .split(/(?:and|,)/) // Split by "and" or comma
          .map((name) => name.trim())
          .filter((name) => name.length > 0);

        const matchedMembers = memberNames
          .map((name) => findClosestMatch(name.toLowerCase(), userData))
          .filter(Boolean);

        if (matchedMembers.length > 0) {
          setProjectDetails((prev) => ({
            ...prev,
            teamMemberRef: matchedMembers.map((emp) => emp.empUserId),
            teamMemberName: matchedMembers.map((emp) => emp.empName),
          }));
          nextStep = "techStack";
          response = `Added team members: ${matchedMembers
            .map((emp) => emp.empName)
            .join(", ")}. What is the techStack for this Project?`;
        } else {
          response = "Couldn't find those team members. Please try again.";
        }
        break;

      case "techStack":
        setProjectDetails((prev) => ({
          ...prev,
          techStack: command,
        }));
        nextStep = "confirmation";
        response = `Tech stack set to "${command}". Here is the task summary:
        - Title: ${projectDetails.projectName}
        - Description: ${projectDetails.projectDes}
        - clientName: ${projectDetails.clientName}
        - Duration: ${projectDetails.projectStartDate} to ${
          projectDetails.projectEndDate
        }
        - Project Manager: ${projectDetails.projectManagerName}
        - Project Manager ID: ${projectDetails.projectManagerRef}
        - Team Members: ${projectDetails.teamMemberName?.join(", ")}
        - Team Member IDs: ${projectDetails.teamMemberRef?.join(", ")}
        - Tech Stack: ${command}  
        
        Say "Yes" to save, "No" to cancel, or "Edit" to modify details.`;
        break;

      case "confirmation":
        if (command.toLowerCase().replace(/\./g, "") === "yes") {
          console.log("success");
          CreateProject()
          response = "Task has been submitted successfully!";
          setIsCreatingProject(false);
          nextStep = "";
        } else if (command.toLowerCase().replace(/\./g, "") === "edit") {
          response =
            "What would you like to edit? projectname, projectdescription, clientname, startdate, enddate, managername, teammembers or techstack?";
          nextStep = "";
          setIsCreatingProject(false);
          setIsEditingProject(true);
        } else {
          response =
            "Please say 'yes' to submit the task or 'edit' to make changes.";
        }
        break;

      default:
        response = "An error occurred. Please try again.";
        setIsCreatingProject(false);
        nextStep = "";
    }

    setCurrentStep(nextStep);
    setBotResponse(response);
    speak(response);
  };

  const handleProjectEdit = (command) => {
    const lowerCommand = command.toLowerCase();
    let response = "";
  
    if (!editField2) {
      // Your existing switch case for selecting field to edit remains the same
    } else {
      let isValid = true;
      let newValue = command;
  
      switch (editField2) {
        case "projectStartDate":
        case "projectEndDate":
          const dateValue = parseDate(command);
          if (dateValue) {
            newValue = dateValue;
          } else {
            isValid = false;
            response = "Invalid date format. Please use DD MM format.";
          }
          break;
  
        case "projectManagerRef":
          const matchedManager = findClosestMatch(command.toLowerCase().trim(), userData);
          if (matchedManager) {
            setProjectDetails(prev => ({
              ...prev,
              projectManagerRef: matchedManager.empUserId,
              projectManagerName: matchedManager.empName
            }));
            response = `Updated manager to "${matchedManager.empName}". What else to edit? Say "done" when finished.`;
            setEditField2("");
            return; // Added return to prevent double state update
          } else {
            isValid = false;
            response = "Manager not found. Please try again.";
          }
          break;
  
        case "teamMemberRef":
          const memberNames = command.split(/(?:and|,)/).map(name => name.trim()).filter(Boolean);
          const matchedMembers = memberNames.map(name => findClosestMatch(name.toLowerCase(), userData)).filter(Boolean);
  
          if (matchedMembers.length > 0) {
            setProjectDetails(prev => ({
              ...prev,
              teamMemberRef: matchedMembers.map(emp => emp.empUserId),
              teamMemberName: matchedMembers.map(emp => emp.empName)
            }));
            response = `Updated team members to: ${matchedMembers.map(emp => emp.empName).join(", ")}. What else to edit? Say "done" when finished.`;
            setEditField2("");
            return; // Added return to prevent double state update
          } else {
            isValid = false;
            response = "Team members not found. Please try again.";
          }
          break;
  
        default:
          // Handle all other fields (projectName, projectDes, clientName, techStack)
          setProjectDetails(prev => ({
            ...prev,
            [editField2]: command
          }));
          response = `Updated ${editField2} to "${command}". What else to edit? Say "done" when finished.`;
          setEditField2("");
          return; // Added return to prevent double state update
      }
  
      // This block is now only for handling invalid inputs
      if (!isValid) {
        response = "Invalid input. Please try again.";
      }
    }
  
    setBotResponse(response);
    speak(response);
  };

  const CreateProject = ()=>{
    try {
      fetch(`${baseUrl}/project/addProject`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization : "Bearer " + token
        },body : JSON.stringify({
          clientName: projectDetails.clientName,
          projectName: projectDetails.projectName,
          projectDes: projectDetails.projectDes,
          projectStartDate: projectDetails.projectStartDate,
          projectEndDate: projectDetails.projectEndDate,
          projectManagerRef: projectDetails.projectManagerRef,
          teamMemberRef: projectDetails.teamMemberRef,
          techStack: projectDetails.techStack,
        })
      }).then((response)=>{
        if(!response.ok){
          throw new Error(response.statusText);
        }
      }).then((data)=>{
        console.log(data);
        if(data.isSuccess){
          setProjectDetails({});
        }
      }).catch((err)=>{
        console.error(err);
      })
    } catch (error) {
      console.log(error);
    }
  }

  // leave
  const handleLeaveRequestStep = (command) => {
    let response = "";
    let nextStep = currentStep;

    console.log("User Input:", command);
    console.log("Current Step Before:", currentStep);

    if (isEditingleave) {
      handleLeaveEdit(command);
      return;
    }

    switch (currentStep) {
      case "":
        response = "Please provide the start date for your leave.";
        nextStep = "startDate";
        break;

      case "startDate":
        const startDate = parseDate(command);
        if (startDate) {
          setLeaveDetails((prev) => ({ ...prev, fromDate: startDate }));
          response =
            "Start date saved. Now, please provide the end date for your leave.";
          nextStep = "endDate";
        } else {
          response =
            "Sorry, I couldn't understand the date. Please say the start date in DD-MM-YYYY format.";
        }
        break;

      case "endDate":
        const endDate = parseDate(command);
        if (endDate) {
          setLeaveDetails((prev) => ({ ...prev, toDate: endDate }));
          response =
            "End date saved. Is this a short leave? Say 'yes' for short leave or 'no' for full day leave.";
          nextStep = "shortLeave";
        } else {
          response =
            "Sorry, I couldn't understand the date. Please say the end date in DD-MM-YYYY format.";
        }
        break;

      case "shortLeave":
        if (command.toLowerCase().replace(/\./g, "") === "yes") {
          setLeaveDetails((prev) => ({ ...prev, isFullDay: false }));
          response =
            "Short leave selected. Please provide the start time for your short leave.";
          nextStep = "startTime";
        } else if (command.toLowerCase().replace(/\./g, "") === "no") {
          setLeaveDetails((prev) => ({ ...prev, isFullDay: true }));
          response =
            "Full-day leave selected. Please provide the reason for your leave.";
          nextStep = "reason";
        } else {
          response =
            "Please say 'yes' for short leave or 'no' for full day leave.";
        }
        break;

      case "startTime":
        const startTime = parseSpokenTime(command);
        if (startTime) {
          setLeaveDetails((prev) => ({ ...prev, startTime }));
          response =
            "Start time saved. Please provide the end time for your short leave.";
          nextStep = "endTime";
        } else {
          response =
            "I couldn't understand the time. Please say it in HH:MM AM/PM format.";
        }
        break;

      case "endTime":
        const endTime = parseSpokenTime(command);
        if (endTime) {
          setLeaveDetails((prev) => ({ ...prev, endTime }));
          response =
            "End time saved. Please provide the reason for your leave.";
          nextStep = "reason";
        } else {
          response =
            "I couldn't understand the time. Please say it in HH:MM AM/PM format.";
        }
        break;

      case "reason":
        setLeaveDetails((prev) => ({ ...prev, reason: command }));
        response =
          "Reason noted. Say 'done' to submit your leave request or 'edit' to make changes.";
        nextStep = "confirmation";
        break;

      case "confirmation":
        if (command.toLowerCase().replace(/\./g, "") === "done") {
          submitLeaveRequest();
          response = "Your leave request has been submitted.";
          setCurrentFlow("");
          nextStep = "";
        } else if (command.toLowerCase().replace(/\./g, "") === "edit") {
          response =
            "What would you like to edit? Leave type, start date, end date, start time, end time, or reason?";
          setIsEditingleave(true);
          nextStep = "edit";
        } else {
          response =
            "Please say 'done' to submit your leave request or 'edit' to make changes.";
        }
        break;

      default:
        response = "An error occurred. Please try again.";
    }

    console.log("Next Step After:", nextStep);
    setCurrentStep(nextStep);
    setBotResponse(response);
    speak(response);
  };

  const handleLeaveEdit = (command) => {
    const lowerCommand = command.toLowerCase().replace(/\./g, "");
    let response = "";

    if (!editField1) {
      if (lowerCommand.includes("leave type")) {
        setEditField1("leaveType");
        response = `Current leave type is "${leaveDetails.leaveType}". Please specify the new leave type (Casual, Sick, or WFH).`;
      } else if (lowerCommand.includes("start date")) {
        setEditField1("fromDate");
        response = `Current start date is "${leaveDetails.fromDate}". Please say the new start date in DD-MM-YYYY format.`;
      } else if (lowerCommand.includes("end date")) {
        setEditField1("toDate");
        response = `Current end date is "${leaveDetails.toDate}". Please say the new end date in DD-MM-YYYY format.`;
      } else if (lowerCommand.includes("start time")) {
        setEditField1("startTime");
        response = `Current start time is "${leaveDetails.startTime}". Please say the new start time in HH:MM AM/PM format.`;
      } else if (lowerCommand.includes("end time")) {
        setEditField1("endTime");
        response = `Current end time is "${leaveDetails.endTime}". Please say the new end time in HH:MM AM/PM format.`;
      } else if (lowerCommand.includes("reason")) {
        setEditField1("reason");
        response = `Current reason is "${leaveDetails.reason}". Please specify the new reason for your leave.`;
      } else if (
        lowerCommand.includes("done") ||
        lowerCommand.includes("edit")
      ) {
        submitLeaveRequest();
        response = "Your leave request has been updated and submitted.";
        setIsEditingleave(false);
      } else {
        response =
          "What would you like to edit? Leave type, start date, end date, start time, end time, or reason?";
      }
    } else {
      let isValid = true;
      let newValue = command;

      if (editField1 === "fromDate" || editField1 === "toDate") {
        newValue = parseDate(command);
        isValid = newValue !== null;
      } else if (editField1 === "startTime" || editField1 === "endTime") {
        newValue = parseSpokenTime(command);
        isValid = newValue !== null;
      } else if (editField1 === "leaveType") {
        newValue = command.trim();
        isValid = ["casual", "sick", "wfh"].includes(newValue.toLowerCase());
        if (!isValid) {
          response =
            "Invalid leave type. Please specify 'Casual', 'Sick', or 'WFH'.";
        }
      } else if (editField1 === "reason") {
        isValid = true;
      }

      if (isValid) {
        setLeaveDetails((prev) => ({
          ...prev,
          [editField1]: newValue,
        }));
        if (editField1 === "leaveType") setLeaveType(newValue);

        response = `New ${editField1} "${newValue}" saved. Say "yes" to edit another field or "done" to submit the leave request.`;
        setEditField1("");
      } else {
        response = "Invalid input. Please try again.";
      }
    }

    setBotResponse(response);
    speak(response);
  };

  const submitLeaveRequest = async () => {
    try {
      const token = localStorage.getItem("token");

      const fromDateTime = combineDateTime(
        leaveDetails.fromDate,
        leaveDetails.startTime ||
          new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
      );
      const toDateTime = combineDateTime(
        leaveDetails.toDate,
        leaveDetails.endTime ||
          new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
      );

      const response = await axios.post(
        `${baseUrl}/smartservice/add`,
        {
          type: leaveType,
          from: leaveDetails.fromDate,
          to: leaveDetails.toDate,
          reason: leaveDetails.reason,

          fullDay: leaveDetails.isFullDay,
          startTime: fromDateTime,
          endTime: toDateTime,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response && response.data) {
        console.log("Leave submitted successfully:", response.data);
      }
    } catch (error) {
      console.error("Error submitting leave:", error);
    }
  };

  return (
    <div className="p-6 max-w-md mx-auto ">
      <div className="text-center space-y-4">
        <button
          onClick={handleOpenPopup}
          className="fixed  bottom-20 right-3 md:right-10 p-4 rounded-full bg-[#1552B2] text-white hover:opacity-90 transition-opacity shadow-lg z-50"
        >
          <Mic className="w-6 h-6" />
        </button>

        {voiceBotpopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-end z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl w-96 h-[90vh] mr-10">
              <div className="flex relative items-center justify-center ">
                <button
                  onClick={toggleListening}
                  className={`p-4 rounded-full ${
                    isListening ? "bg-red-500" : "bg-[#1552B2]"
                  } text-white hover:opacity-90 transition-opacity`}
                >
                  {isListening ? (
                    <MicOff className="w-6 h-6" />
                  ) : (
                    <Mic className="w-6 h-6" />
                  )}
                </button>
                <button
                  onClick={handleClosePopup}
                  className="absolute right-0 top-0 hover:text-gray-500 text-black"
                >
                  <span class="material-symbols-outlined">close</span>
                </button>
              </div>
              <div className="mt-4 ">
                {isListening && (
                  <div className="text-[#1E3354] font-medium">Listening...</div>
                )}
                {spokenText && (
                  <div className="mt-2">
                    <h2 className="font-semibold">You said:</h2>
                    <p className="text-gray-600">{spokenText}</p>
                  </div>
                )}
                {botResponse && (
                  <div className="mt-4 ">
                    <h2 className="font-semibold flex items-center gap-2">
                      <Volume2 className="w-4 h-4" />
                      Response:
                    </h2>
                    <p className="text-gray-600 whitespace-pre-line ">
                      {botResponse}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Voicebot;
